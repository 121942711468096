@use '../../../styles/modules.scss' as *;

.root-home {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
}

.root-admin {
    position: relative;
    display: flex;
}

.buttonSelector {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    outline: none;
    color: #265771;

    .lg-long {
        display: block;
    }

    .lg-short {
        display: none;
    }
}

.buttonSelectorActive {
    font-weight: bolder;
}

@include media-breakpoint-down(sm) {
    .buttonSelector {
        .lg-long {
            display: none;
        }
    
        .lg-short {
            display: block;
        }
    }
}
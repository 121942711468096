@use '../../../../styles/modules' as *;

.root {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;

  &.isProfile {
    padding-top: 30px;
    border-top: 1px solid $co-inputBorder;
  }

  &.isCentered {
    justify-content: space-evenly;
  }

  [class*='button'] {
    margin-top: 0;
    & + [class*='button'] {
      margin-left: 20px;
    }
  }

  [class*='formLayout'] & {
    margin-top: 20px;
  }
}

@include media-breakpoint-down(sm) {
  .root {
    &.full {
      margin: 0px 0px;
      padding: 0px;
      gap: 20px;
      button {
        width: 100%;
      }

      [class*='button'] {
        margin-top: 0;
        & + [class*='button'] {
          margin-left: 0px;
        }
      }
    }
  }
}
@use '../../../styles/modules.scss' as *;

%button {
  @include font($fs: $fs-medium, $fw: $fw-book, $lh: rem(19px), $co: $co-white);
  border: 0;
  padding: 16px 41px 15px;
  transition: background 0.3s ease-in-out;
}

@mixin hoverStyle($color) {
  background-color: $color;
}

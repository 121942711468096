@use '../../../../styles/modules.scss' as *;
@use '../buttonsPresets.module.scss' as *;

.button {
  @extend %button;

  &Positive {
    background-color: $co-button;
    &:hover {
      @include hoverStyle($co-buttonHover);
    }
  }

  &Negative {
    background-color: $co-greyText;
    &:hover {
      @include hoverStyle($co-greyDark);
    }
  }

  &.isReset {
    background: url('../../../../assets/icon_refresh.svg') no-repeat center;
    padding: 20px;
  }

  &.isDownload {
    background: url('../../../../assets/downloadCSV.svg') no-repeat center;
    padding: 20px;
  }

  &:hover {
    cursor: pointer;
  }

  [class*='heading'] & {
    padding: 13px 40px 11px;
    @include font(
      $fs: $fs-medium,
      $fw: $fw-book,
      $lh: rem(16px),
      $co: $co-white
    );
    display: inline-block;
  }
}

@include media-breakpoint-up(lg) {
  .button {
    @include font($fs: rem(18px), $lh: rem(21px));

    [class*='heading'] & {
      @include font($fs: rem(18px), $lh: rem(21px));
    }
  }
}

.icon {
  border: 0;
  background: 0;

  svg {
    height: 16px;
    width: 12px;
    fill: $co-greyText;
  }

  &:hover {
    svg {
      fill: $co-cadetblue;
    }
  }
}

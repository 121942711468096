/*@function rem($size) {
  @return $size / $fs-root * 1rem;
}*/

.form {
  &.small {
    width: 350px;
  }
  &.medium {
    max-width: 650px;
  }
  &.center {
    margin: 0 auto;
  }
  .title {
    font-size: rem(18px);
    color: $t-dark;
    font-weight: $t-semi-bold;
  }
  .subtitle {
    font-size: rem(14px);
    color: $t-light;
    font-weight: $t-regular;
    margin-top: $fs-big;
  }
  & > .inputWrapper ~ .inputWrapper {
    margin-top: 14px;
  }
  .inputWrapper {
    position: relative;
    margin-top: 21px;
    &.hasIcon {
      .icon {
        position: absolute;
        left: 10px;
        z-index: 1;
        &.email {
          top: 7px;
          bottom: 8px;
        }
        &.password {
          top: 2px;
          left: 12px;
        }
      }
      input {
        padding-left: 45px;
      }
    }
    p {
      font-size: $fs-small;
    }
  }

  input {
    @include font(
      $fs: $fs-small,
      $fw: $t-regular,
      $co: $t-light,
      $lh: rem(17px)
    );
    height: 35px;
    border: 1px solid $bo-light;
    border-radius: 3px;
    background-color: $bg-global;
    padding: 13px 20px 12px;
    max-width: 350px;
    width: 100%;
    &[type='checkbox'] {
      width: 16px;
      height: 16px;
      vertical-align: baseline;
      margin-right: 8px;
    }
    &[type='radio'] {
      width: 16px;
      height: 16px;
      vertical-align: baseline;
      margin-right: 8px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  select {
    @include font(
      $fs: $fs-small,
      $fw: $t-regular,
      $co: $t-light,
      $lh: rem(17px)
    );
    height: 35px;
    background: url('../../assets/icon_arrow.svg') no-repeat right 22px center;
    border: 1px solid $bo-light;
    border-radius: 3px;
    background-color: $bg-global;
    -webkit-appearance: none;
    padding: 7px 20px 6px;
    max-width: 350px;
    width: 100%;
    @-moz-document url-prefix() {
      padding-left: 15px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  button,
  a.button {
    @include font(
      $fs: $fs-small,
      $fw: $t-medium,
      $co: $t-lightest,
      $lh: rem(17px)
    );
    appearance: button;
    padding: 1px 6px;
    height: 35px;
    border: none;
    border-radius: 3px;
    background-color: $bg-dark;
    margin-top: 14px;
    transition: background-color 300ms linear;
    min-width: 150px;
    max-width: 350px;
    cursor: pointer;
    display: inline-block;
    &.lg {
      width: 100%;
    }
    &:hover {
      background-color: lighten($bg-dark, 10%);
      cursor: pointer;
    }
  }
  a.button {
    line-height: 35px;
  }
  label {
    @include font(
      $fs: $fs-small,
      $fw: $t-regular,
      $co: $t-light,
      $lh: rem(17px)
    );
    display: block;
    span {
      color: $t-light;
    }
    &.forRadio {
      font-size: 1rem;
    }
  }
}
.error {
  @include font($fs: $fs-small, $fw: $t-regular, $co: $t-error, $lh: rem(17px));
}
hr {
  border: 1px solid $bg-lighter;
  margin: $m2 auto;
  &.small {
    max-width: 350px;
    margin-left: 0;
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  &.small {
    max-width: 350px;
  }
}

.inline {
  display: inline-block !important;
}

@use '../../styles/modules.scss' as *;

.container {
  padding: 0rem 2rem;
  text-align: center;

  [class*='selectDropDown'] {
    width: 340px;
  }

  [class*='errorText'] {
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 18px;
  }
}

.title {
  @include font($fs: rem(18px), $fw: $fw-bold, $lh: rem(22px), $co: $co-black);
  margin-bottom: 23px;
  position: relative;
  display: inline-block;
  margin: 0 40px 23px;
}

.icon {
  position: absolute;
  left: -39px;
  top: -2px;
  height: 29px;
  width: 29px;
  fill: $co-greyText;

  &.isIconTrash {
    height: 18px;
  }

  &.isIconSend {
    top: 3px;
  }
}

.description {
  @include font($fs: $fs-medium, $fw: $fw-book, $lh: rem(26px), $co: $co-black);
  margin-bottom: 30px;
  text-align: center;
}

@include media-breakpoint-up(lg) {
  .title {
    @include font($fs: rem(22px), $lh: rem(26px));
  }

  .description {
    @include font($fs: rem(18px), $lh: rem(28px));
  }
}

.filter {
  input {
    width: 100%;
  }
}

.buttonsWrapper {
  margin-top: 30px;

  button {
    padding: 22px 26px;
    line-height: 26px;
    width: 100%;
    max-width: 202px;
    height: 95px;
  }

  button + button {
    margin-left: 20px;
  }
}

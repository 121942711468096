@use '../../../../styles/modules.scss' as *;

.root {
  width: 100%;

  [class*='inputText'],
  [class*='selectDropDown'] {
    width: 100%;
  }
  [class*='inputField_row'],
  [class*='customDonationInput_row'],
  [class*='selectField_row'] {
    display: contents;
  }

  [class*='errorText'] {
    grid-column: 2;
    // Con esto deshacemos el espacio entre filas
    margin-top: 0px;
  }

  [class*='buttonsGroup'] {
    grid-column: 2;
  }

  [class*='button_button'] {
    margin-top: 20px;
    grid-column: 2;
    display: inline-block;
    & + [class*='button_button'] {
      margin-left: 20px;
    }
  }

  &.isProfile,
  &.isSecurity {
    max-width: ele-formWidth(md);
    margin: auto;

    [class*='inputText'],
    [class*='selectDropDown'] {
      &[class*='hasError'] {
        background-image: none;
      }
    }

    [class*='button_button'] {
      margin-top: 0;
    }

    [class*='buttonsGroup'] {
      grid-column: span 2;
    }
  }
}

.formTitle {
  background-color: $co-greyBgDark;
  padding: 18px 20px;
  width: 100%;
  @include font(
    $fs: $fs-big,
    $fw: $fw-bold,
    $lh: rem(20px),
    $co: $co-cadetblue
  );
  margin-bottom: 30px;
}

.description {
  @include font(
    $fs: $fs-small,
    $fw: $fw-book,
    $lh: rem(20px),
    $co: $co-greyText
  );

  .isProfile &,
  .isSecurity & {
    padding-left: 20px;
  }
}

.form {
  max-width: ele-formWidth(md);
  width: 100%;
  margin: 36px auto 0;
  padding: 0;
  display: grid;
  grid-template-columns: ele-labelWidth(xs) 1fr;
  row-gap: 10px;
  column-gap: 10px;
  align-items: center;

  .isProfile &,
  .isSecurity & {
    padding-left: 20px;
    grid-template-columns: ele-labelWidth(md) 1fr;
  }

  .isSecurity & {
    display: block;
  }
}

@include media-breakpoint-up(lg) {
  .root {
    &.isProfile,
    &.isSecurity {
      max-width: ele-formWidth(lg);
    }
  }

  .form {
    max-width: ele-formWidth(lg);
    grid-template-columns: ele-labelWidth(md) 1fr;

    .isProfile &,
    .isSecurity & {
      grid-template-columns: ele-labelWidth(lg) 1fr;
    }
  }
}

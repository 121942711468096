@use '../../../../styles/modules.scss' as *;
$wrapperPaddingTop: 16px;
$logoHeight: 66px;
$logoMarginBottom: 52px;

.root {
  display: flex;
  min-height: 100vh;
  background-color: $co-white;
  font-family: $font-dft;
  font-weight: $fw-dft;
  flex-direction: column;
}

.container {
  display: flex;
  min-height: calc(100vh - 124px);
}

.logoPobles {
  margin-left: calc((490px - 156px) / 2);
  margin-bottom: 50px;
}

.logoPoblesMobile {
  margin: 48px auto;
  display: none;
  width: 100%;
  padding: 8px;
}

.mainImageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: center;*/
  width: 40%;
  /*padding: 0 15px;*/
  padding-top: calc((100vh - 400px) / 3);
  background-color: $bg-lightest;
  text-align: center;
  .wrappedImage {
    .alignCenter {
      display: inline-block;
    }
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: $bg-global;
  width: 60%;
  max-width: 800px;
  padding: 20px 83px 20px 83px;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 124px;
  background-color: $bg-lightest;
  justify-content: space-between;
  padding: 0px 32px;
}

.logo {
  display: block;
  max-width: calc(100% / 12);
  height: auto;
  object-fit: contain;
}

@include media-breakpoint-down(sm) {
  .footer {
    display: none;
  }

  .container {
    min-height: 100vh;
  }

  .mainImageWrapper {
    display: none;
  }

  .wrapper {
    width: 100%;
    margin: 0 auto;
    align-items: center;
    padding: 20px 15px;
  }

  .form .wrapper {
    margin: -160px auto 0;
  }

  .logoPobles {
    display: none;
  }

  .logoPoblesMobile {
    display: block;
  }
}

@use '../../../styles/modules.scss' as *;

.root {
  width: 100%;
  position: relative;
}

.button {
  border: 0;
  background-color: transparent;
  width: 100%;
  padding: 0;
  width: 30px;
  height: 30px;
  position: relative;
  z-index: 0;

  &:hover {
    cursor: pointer;
  }
}

.menu {
  display: none;
  padding: 10px 22px;
  min-width: 200px;
  border-radius: 4px;
  background-color: $co-white;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  position: absolute;
  right: 0;
  top: 22px;
  z-index: $zi-popup;

  &.isOpen {
    display: block;
  }
}

.item {
  & + & {
    margin-top: 4px;
  }
}

.action {
  display: flex;
  padding: 7px 0;

  &:hover {
    cursor: pointer;

    .icon,
    svg {
      fill: $co-cadetblueDark;
      color: $co-cadetblueDark;
    }

    .text {
      color: $co-cadetblueDark;
    }
  }
}

.iconWrapper {
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon,
.menu svg {
  fill: $co-greyText;
  color: $co-greyText;
}

.text {
  @include font($fs: $fs-small, $fw: $fw-book, $lh: rem(16px), $co: $co-black);

  .iconWrapper + & {
    margin-left: 8px;
  }
}

@include media-breakpoint-up(lg) {
  .button {
    &:before {
      height: 0;
      width: 0;
      content: '';
      display: block;
      border-radius: 50%;
      transition:
        width 0.2s ease-in-out,
        height 0.2s ease-in-out;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: $co-greyDark;
      opacity: 0.2;
      z-index: $zi-negative;
    }

    &:hover {
      cursor: pointer;
      &:before {
        height: 100%;
        width: 100%;
      }
    }
  }
}
